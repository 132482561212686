// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/settings/provider.ts"
);
import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import { SettingsQuery } from '~storefront/generated/graphql'
import { QueryOptions, sdk } from '~storefront/graphqlWrapper'

export const settings = async (
  options: QueryOptions,
): Promise<SettingsQuery> => {
  return sdk.settings(undefined, options)
}
