// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/footer/SocialLink.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/footer/SocialLink.tsx");
  import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

export function SocialLink({
  social
}) {
  return <a className="w-10 h-10 rounded-full border border-gray-200 dark:bg-slate-200 dark:border-slate-500 dark:opacity-20 flex justify-center items-center hover:bg-gray-100" href={social.link}>
      <img src={social.icon} className="text-gray-900 dark:text-slate-400" />
    </a>;
}
_c = SocialLink;
var _c;
$RefreshReg$(_c, "SocialLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;