// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/utils/useActiveOrder.tsx"
);
import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import { useFetcher } from '@remix-run/react'
import { useState, useEffect, useMemo } from 'react'

import { CartLoaderData } from '~storefront/routes/api.active-order'
import {
  OrderDetailFragment,
  OrderLineCustomFieldsInput,
} from '~storefront/generated/graphql'

export function useActiveOrder(initialOrder?: OrderDetailFragment | null) {
  const [order, setOrder] = useState<OrderDetailFragment | undefined | null>(
    initialOrder,
  )
  const activeOrderFetcher = useFetcher<CartLoaderData>()

  useEffect(() => {
    // TODO this only works with js enabled
    if (
      !order &&
      activeOrderFetcher.state === 'idle' &&
      activeOrderFetcher.data == null
    ) {
      activeOrderFetcher.load('/api/active-order')
    }
  }, [order, activeOrderFetcher])

  useEffect(() => {
    if (activeOrderFetcher.data) {
      setOrder(activeOrderFetcher.data.activeOrder as OrderDetailFragment)
    }
  }, [activeOrderFetcher.data])

  function refresh() {
    activeOrderFetcher.load('/api/active-order')
  }

  const removeItem = (lineId: string) => {
    activeOrderFetcher.submit(
      {
        action: 'removeItem',
        lineId,
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }

  const applyCoupon = (couponCode: string) => {
    activeOrderFetcher.submit(
      {
        action: 'applyCoupon',
        couponCode,
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }
  const removeCoupon = (couponCode: string) => {
    activeOrderFetcher.submit(
      {
        action: 'removeCoupon',
        couponCode,
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }

  const adjustOrderLine = (
    lineId: string,
    quantity: number,
    customFields: OrderLineCustomFieldsInput = {},
  ) => {
    activeOrderFetcher.submit(
      {
        action: 'adjustItem',
        lineId,
        quantity: quantity.toString(),
        customFields: JSON.stringify(customFields),
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }

  return {
    activeOrderFetcher,
    activeOrder: order,
    removeItem,
    adjustOrderLine,
    applyCoupon,
    removeCoupon,
    refresh,
    loading: activeOrderFetcher.state !== 'idle',
  }
}
